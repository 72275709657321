import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { BrowserRouter as Router,Link} from "react-router-dom";
import React from "react";
import { connect, } from "react-redux";
import "../../../TeamsApp/antd-minified.css";
import "../../../TeamsApp/antd-minified.css";
import AuthService from "../../../Config/msalConfig";
import HeaderComponent from "../../components/layout/headerComponent/headerComponent";
import MobexLogin from "./mobexLogin";
import SystemUnavailable from "../../loginError/SystemUnavailable";
// import CallTowerLogin from "./callTowerLogin";
import Authentication from "../../loginError/authentication";
import mobexLogin from "./mobexLogin";


class MobexMain extends React.Component<any, any> {
  state = {
    openPage: ""
  };
  componentDidMount() {
    //AuthService.initSignin()
  }
  render() {
    return this.props.openPage ? (
      this.props.checkUser ? <Authentication /> :
        <MobexLogin />
    ) : this.props.trailExpier ? (<SystemUnavailable />) : this.props.sharedError ? (<SystemUnavailable />) :
      this.props.loggedInUser &&
        typeof this.props.loggedInUser !== "string" ? (<HeaderComponent></HeaderComponent>) : (
        <Router>
          <div>
            <Link to="/#/mobex">
              <MobexLogin loginStatus={this.props.loggedInUser}></MobexLogin>
            </Link>
          </div>
        </Router>
      );
  }
}

const mapStateToProps = (props: any) => {
  return {
    loggedInUser: props.reduxReducer.loggedInUser,
    openPage: props.reduxReducer.openPage,
    trailExpier: props.reduxReducer.trailExpier,
    sharedError: props.reduxReducer.sharedError,
    checkUser: props.reduxReducer.checkUser,
  };

};
  export default  connect(mapStateToProps, null)(MobexMain);