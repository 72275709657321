import React from "react";
import AuthHelper from "../../../Config/msalConfig";
import logo from "../../../TeamsApp/images/CT Text Logo-2.png";
import logo1 from "../../../TeamsApp/images/logoLogin.png";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/actions";
import { ThunkDispatch } from "redux-thunk";
import { useHistory } from "react-router-dom";

function Login(props: any) {
  const history = useHistory();

  const setLoginUser = () => {
    debugger;
    // let webexC:any =localStorage.getItem("webexC");
    localStorage.clear();
    sessionStorage.clear();

    //  localStorage.setItem(
    //      "webexC",
    //      webexC
    //    );
    //AuthHelper.signIn();
    //props.checkRegisterUser(true);
  };

  const openRegister = () => {
    debugger;
    setTimeout(() => {
      window.location.reload();
    }, 200);
    // history.push('/register')  
    // window.location.reload();
    //  setTimeout(() => {
    //    history.push('/register')  
    //   //  window.location.reload();
    //   debugger
    // } , 200);
  };

  const goToYakChat = () => {
    var deepLinkUrl = "https://teams.microsoft.com/l/app/a01c75ea-e5aa-4406-b119-ce559ba1f13f?source=share-app-dialog"; 
    window.open(deepLinkUrl, "_blank");
  }

  return (
    <section className="userlog_sec error_sec" style={{ backgroundColor: "#6bbc15" }}>
      <div className="container">
        <div className="login_box" style={{ textAlign: "center" }}>
          <div className="error_logo logoLogin_div text-center">
            {props.loginStatus === "loggingIn" ? (
              <img
                src={logo}
                alt="logo"
                style={{
                  width: "calC(50vw + 15vh)",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: "400px",
                }}
              />
            ) : (
              <>
                <img src={logo} className="errorSignInLogo" alt="logo" />
                <div style={{ color: "#fff" }} className="welcome_Line">
                This version of CT Text is no longer supported. <br /> <br />
                  To continue using CT Text, please upgrade to the latest version from the Microsoft Teams App Store or contact support@calltower.com for assistance
                </div>
              </>
            )}
          </div>
          {props.loginStatus === "loggingIn" ? (
            <div className="authen_txt" style={{ backgroundColor: props.whiteLabelBrand !== "calltower" ? "#ffff" : "none" }}>
              <img src="images/lock.svg" alt="yakchat logo" /> Microsoft Secure Authentication
            </div>
          ) : (
            <>
              <button
                id="loginButtonMainInTeams"
                onClick={goToYakChat} // Update the onClick to redirect to YakChat
                className="btn cstm_btn gry_btn"
                style={{ marginTop: "20px", backgroundColor: "#ffffff", color: "#000" }}
              >
                Upgrade Now
              </button>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand: props.reduxReducer.whiteLabelBrand,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    checkRegisterUser: (checkUser: boolean) => {
      debugger;
      dispatch<any>(actions.checkRegisterUser(checkUser));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
