import React, { useState } from "react";
import AuthHelper from "../../../Config/msalConfig";
import logo from "../../../TeamsApp/images/CT Text Logo.png";
import sponser from "../../../TeamsApp/images/Powered_by_YakChat_White-01.png"
import sponser2 from "../../../TeamsApp/images/commsgroup-alt-logo 2.png"
import { ReactComponent as LockIcon } from "../../images/lock.svg";
import { ReactComponent as Logo } from "../../images/commsgroup-alt-logo 2.svg";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

const antIcon = <Loading3QuartersOutlined style={{ fontSize: 36,color:"#fff",backgroundColor:"none",display:"flex",marginRight:"10%" }} spin  />;
export  function CommsChoice(props: any) {
  const handleLogin =()=>{
    //AuthHelper.signIn();
    //setSpinnerValueState(true)
  }
  const openCommsRegister =()=>{
    setTimeout(() => {
      window.location.reload(true)
     }, 200);
  }
const [spinnerValueState,setSpinnerValueState]=useState(false);
  return (
    <section className="userlog_sec error_sec commsLogin" style={{backgroundColor:"#000045"}}>
      <div className="container">
        <span className="login_box inner_loginBox"  style={{ textAlign: "center" }}>
          <div className="text-center" style={{display:"flex",justifyContent:"center"}}>
            {props.loginStatus === "loggingIn" ? (
              <img  className="app_logo"
                src={sponser2}
                alt="logo"
                style={{
                  position: "absolute", top: "50%",  left: "50%",transform: "translate(-50%, -50%)",
                  height:"auto",   
                }}
              />
            ) : (
              <img src={sponser2} alt="logo"  />
            )}
          </div>
          {props.loginStatus === "loggingIn" ? (
            <>
             <Spin spinning={true} indicator={antIcon}
              className="login_spinner " style={{ zIndex: 9999, }} /> 
            <div className="commssponser calltower_txt" >
              <img src={sponser} alt="#" style={{
                height:"auto" 
              }}/>
               {/* Microsoft Secure Authentication */}
            </div>
            <div className="authen_txt" style={{ backgroundColor:"#ffff" }}>
              <LockIcon fill="#25C6DA" className="calltower-lock-svg"/> Microsoft Secure Authentication
            </div>
             </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
           <div style={{ color: "white" }} className="welcome_Line">
           This version of CommsChat  is no longer supported.   <br /> <br />
           To continue using CommsChat , please contact support@commschoice.com to upgrade to the latest version.
                </div>
            </div>
          )}
        </span>
      </div>
    </section>
  );
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand:props.reduxReducer.whiteLabelBrand
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setRegisterTheme:(registerTheme:any) => {  
      dispatch<any>(actions.setRegisterTheme(registerTheme));
    }, 
  };
};
export default connect(mapStateToProps,mapDispatchToProps)(CommsChoice);
