import React, { useState } from "react";
import AuthHelper from "../../../Config/msalConfig";
import logo from "../../../TeamsApp/images/Mobex-newlogo.svg";
import sponser from "../../../TeamsApp/images/Powered_by_YakChat_White-01.png"
import { ReactComponent as LockIcon } from "../../images/greenLock.svg";
import { Spin } from "antd";
import {ReactComponent as Loader} from "../../images/mobexloader.svg";
import logoMobile from "../../../TeamsApp/images/mobilemobex.svg";
import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/actions";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

const antIcon = <Loader className="OneNet_spinner" style={{ fontSize: 36, backgroundColor: "none", display: "flex", marginRight: "10%" }}  />;
// export  function CallTowerLogin(props: any) {

class MobexLogin extends React.Component<any, any> {
  state = {
    SpinnerValueState: false
  };
  handleLogin = () => {
    //AuthHelper.signIn();
    // AuthHelper.initSignin();
    //this.setState({
    //  SpinnerValueState: true
    //})
    
  }
  openCalltowerRegister = () => {
    setTimeout(() => {
      window.location.reload()
    }, 200);
  }
  // const [spinnerValueState,setSpinnerValueState]=useState(false);
  render() {
    return (
      <section className="userlog_sec error_sec  mobexLogin">
        <div className="container">
          <span className="login_box inner_loginBox" style={{ textAlign: "center" }}>
            <span className="text-center" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.props.loginStatus === "loggingIn" ? (
                <>
                <img className="app_logo call-tower-logo desktop_svg"
                  src={logo}
                  alt="logo"
                  style={{
                    width: "300px",
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "auto",
                    marginTop: "-10px"
                  }}
                />
                <img className="app_logo call-tower-logo Mobile_svg"
                src={logoMobile}
                alt="logo"
                style={{
                  width: "300px",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "auto",
                  marginTop: "-10px"
                }}
              />
              </>
              ) : (
                <img src={logo} alt="logo" className="app_logo" style={{ height: "auto", width: "300px", }} />
              )}
            </span>
            {this.props.loginStatus === "loggingIn" ? (
              <>
                <Spin spinning={true} indicator={antIcon}
                  className="login_spinner " style={{ zIndex: 9999, }} />
                <div className=" calltower_txt" >
                  <img src={sponser} alt="#" style={{
                    height: "auto"
                  }} />
                  {/* Microsoft Secure Authentication */}
                </div>
                <div className="authen_txt" style={{ backgroundColor: "#ffff" }}>
                  <LockIcon fill="#241F58" className="calltower-lock-svg" /> Microsoft Secure Authentication
                </div>
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                  <div style={{ color: "black" }} className="welcome_Line">
                  This version of Mobex SMS/MMS is no longer supported.  <br /> <br />
                  To continue using Mobex SMS/MMS, please contact support@mobex.biz to upgrade to the latest version.
                </div>
              </div>
            )}
          </span>
        </div>
      </section>
    );
  }
}
const mapStateToProps = (props: any) => {
  return {
    whiteLabelBrand: props.reduxReducer.whiteLabelBrand,
    registerTheme: props.reduxReducer.registerTheme
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    setRegisterTheme: (registerTheme: any) => {
      dispatch<any>(actions.setRegisterTheme(registerTheme));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobexLogin);
// export default connect(mapStateToProps,mapDispatchToProps)(CallTowerLogin);
